<template>
    <modal ref="modalEditarDriver" titulo="Editar Driver" tamano="modal-lg" no-aceptar adicional="Editar" @adicional="editarDriver()">
        <div class="row mx-0 mb-4 justify-center">
            <div class="col-auto">
                <slim-cropper 
                ref="fotoDriver" 
                :options="slimOptions" 
                class="border cr-pointer"
                style="height:162px;width:162px;background-color:#F5F5F5;border-radius:6px;" 
                />
            </div>
        </div>
        <div class="row mx-0 justify-center mb-4 mt-2">
            <div class="col-5">
                <p class="text-general pl-3 f-14">Nombre</p>
                <ValidationProvider v-slot="{errors}" rules="required" name="Nombre">
                    <el-input v-model="nombre" placeholder="Nombre del Driver" class="w-100" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
                <p class="text-general pl-3 f-14 mt-4">Documento de identificación</p>
                <ValidationProvider v-slot="{errors}" rules="required" name="Identificación">
                    <el-input v-model="documento" placeholder="Teléfono" class="w-100" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
            </div>
            <div class="col-5">
                <p class="text-general pl-3 f-14">Teléfono</p>
                <ValidationProvider v-slot="{errors}" rules="required" name="Número de documento">
                    <el-input v-model="telefono" placeholder="Número de documento" class="w-100" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
                <p class="text-general pl-3 f-14 mt-4">Id_cargamos</p>
                <ValidationProvider v-slot="{errors}" rules="required" name="Id">
                    <el-input v-model="id" placeholder="Id" class="w-100" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
            </div>
        </div>
    </modal>
</template>

<script>
import { extend } from 'vee-validate';
import Drivers from '~/services/drivers/drivers'
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombre: '',
            telefono: '',
            documento: '',
            id: '',
            idDriver: 0

        }
    },
    methods: {
        toggle(form){
            this.$refs.modalEditarDriver.toggle();
            if(form.imagen!="/img/no-imagen/sin_cliente.svg")this.$refs.fotoDriver.instanciaCrop.load(form.imagen)
            this.nombre = form.nombre
            this.documento = form.identificacion
            this.telefono = form.telefono
            this.id = form.cargamos_id
            this.idDriver = form.id
        },
        async editarDriver(){
            let idDriver = this.idDriver
            let archivo = this.$refs.fotoDriver.instanciaCrop.dataBase64.output
            let form = {
                nombre: this.nombre,
                identificacion: this.documento,
                telefono: this.telefono,
                cargamos_id: this.id,
                imagen: archivo.image
            }
            if(form.nombre && form.identificacion && form.telefono && form.cargamos_id){
                try {
                    const { data } = await Drivers.editarDriver(idDriver,form);
                    this.notificacion("Éxito","Driver editado con éxito","success")
                    this.$emit('update')
                    this.$refs.modalEditarDriver.toggle();               
                } catch (e){
                    return this.error_catch(e);
                }
            }else this.notificacion("Error","Campos obligatorios","warning")
        },
        limpiar(){
            this.nombre = ''
            this.telefono = ''
            this.documento = ''
            this.id = ''
            this.$refs.fotoDriver.instanciaCrop.remove()
            
        },
    }
}
</script>